import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: lazyLoad("Login")
  },
  {
    path: '/register',
    name: 'Register',
    component: lazyLoad("Register")
  },
  {
    path: '/member',
    component: lazyLoad("Member/Base"),
    children: [
      {
        path: '',
        redirect: 'mornitor'
      },
      {
        path: 'profile',
        name: 'MemberProfile',
        component: lazyLoad("Member/Profile")
      },
      {
        path: 'mornitor',
        name: 'MemberMornitor',
        component: lazyLoad("Member/Mornitor")
      },
      {
        path: 'daily_energy',
        name: 'MemberDailyEnergy',
        component: lazyLoad("Member/DailyEnergy")
      },
      {
        path: 'report',
        name: 'MemberReport',
        component: lazyLoad("Member/Report")
      },
      {
        path: 'log',
        name: 'MemberLog',
        component: lazyLoad("Member/Log")
      },
      {
        // สาขา + ID
        path: 'branch/:id',
        name: 'MemberMornitor',
        component: lazyLoad("Member/Branch")
      },
      {
        // อุปกรณ์ Meter + ID
        path: 'node/:id',
        name: 'MemberMornitor',
        component: lazyLoad("Member/Node")
      },
      {
        // อุปกรณ์ Logger + ID
        path: 'node_logger/:id',
        name: 'MemberMornitor',
        component: lazyLoad("Member/NodeLogger")
      },
      {
        // เครื่อง + ID
        path: 'device/:id',
        name: 'MemberMornitor',
        component: lazyLoad("Member/Device")
      },
    ]
  },
  {
    path: '/home',
    name: 'Home',
    component: lazyLoad("Home")
  },
  {
    path: '/about',
    name: 'About',
    component: lazyLoad("About")
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
