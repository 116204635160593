<script>
import Axios from 'axios';
import moment from 'moment'
var md5 = require('md5');

const LocahHost = false;
// const LocahHost = true;

// jumbenz@gmail.com
// @123456789

// patcharawan.pat@gmail.com
// 12345678

// ID
// PV_voltage //PV1-5 voltage
// PV_current //PV1-5 current
// PV_power //PV1-5 Power
// Output_active_power //Output active power
// Output_reactive_power //Output reactive power
// Grid_frequency //Grid frequency
// A_phase_voltage //A-phase voltage
// A_phase_current //A-phase current
// B_phase_voltage //B-phase voltage
// B_phase_current //B-phase current
// C_phase_voltage //C-phase voltage
// C_phase_current //C-phase current
// Total_production //Total production
// Total_generation_time //Total generation time
// Today_production //Today production
// Today_generation_time //Today generation time
// Key

export default {
  data() {
    return {
      local_host: LocahHost,
      secret: `6c6756c82eca0bdd4c1340fa24d4f6081e906aeb`,
      Axios: Axios,
      api: null,
      tokenPassword: "",
      loading_screen: true,
    }
  },
  created() {
    if (this.local_host) {
      this.api = "http://localhost:8000/PowerMeter/api/";
    } else {
      this.api = "http://powermeterapi.tnj-electronics.com/";
    }
  },
  methods: {
    LocalStorage(name, set = new Object()) {
      var taskArrayString = localStorage.getItem(name);
      if (taskArrayString !== null && typeof taskArrayString === "string") {
        // console.log("Succesfully retrieved 'tasks' and contents.");
      } else {
        // console.log("Succesfully retrieved 'tasks', contents were not a string or held no contents. Contents of 'tasks' reset to empty object");
        var emptyArray = JSON.stringify(set);
        localStorage.setItem(name, emptyArray);
        taskArrayString = localStorage.getItem(name);
      }
      return JSON.parse(taskArrayString);
    },
    check_login(user) {
      if (user.id && user.enable) {
        setTimeout(() => this.loading_screen = false, 1000);
        return true;
      } else {
        this.redirect("/");
      }
    },
    isAdmin(user) {
      return parseInt(user.status) == 1
    },
    md5(val) {
      return md5(val);
    },
    redirect(path) {
      if (this.$route.path !== path) this.$router.push(path)
    },
    uniq_id() {
      var n = Math.floor(Math.random() * 11);
      var k = Math.floor(Math.random() * 1000000);
      var m = String.fromCharCode(n) + k;
      return m;
    },
    save_token(data, pwd) {
      let tokenData = new Object();
      tokenData.email = data.email;
      tokenData.pwd = pwd;
      tokenData.token = data.token;
      localStorage.setItem("_TNJ_PowerMeter_tokenData_", JSON.stringify(tokenData));
    },
    logout() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to logout?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            this.logout_of_system();
          }
        });
    },
    logout_of_system() {
      // console.log(this.client);
      if (this.client) {
        this.client.end();
        console.error("Disconnected: client.end");
      }

      let data = this.LocalStorage("_TNJ_PowerMeter_tokenData_");
      let logout = { email: data.email, comment: "ออกจากระบบ" };
      this.save_token(logout, data.pwd);
      this.redirect("/");
    },
    leftPad(number, targetLength) {
      var output = number + '';
      while (output.length < targetLength) {
        output = '0' + output;
      }
      return output;
    }
  },
  filters: {
    moment(date) {
      return moment(date).format('Do MMMM YYYY');
    },
    date_message: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a');
    },
    date_project: function (date) {
      return moment(date).format('MMMM Do, YYYY h:mm a') + " (PST)";
    },
    date_data: function (date) {
      return moment(date).format('Do MMM YYYY, h:mm:ss A');
    },
    date_list: function (date) {
      var fromNow = moment(date).fromNow();
      return moment(date).calendar(null, {
        lastWeek: '[Last] dddd',
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        sameElse: function () {
          return "[" + fromNow + "]";
        }
      });
    },
    highlight: function (words, query) {
      return words.replace(query, '<span class="highlight">' + query + '</span>')
    }
  }
};
</script>
