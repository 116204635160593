<script>
export default {
  methods: {
    async submit_new_branch(input){
      var url = this.api+'branch/add/'+this.secret;
      var data = new FormData();
      if(input.adminMode){
        data.set('u_id', input.u_id);
      }else{
        data.set('u_id', this.userData.id);
      }
      data.set('name', input.name);
      data.set('detail', input.detail);
      data.set("secret", this.secret);
      await this.Axios.post(url, data)
      .then((response)=>{
        // console.log(response.data);
        if(response.data.error==0){
          this.branchs.push(response.data.data);
          this.$swal("","เพิ่มข้อมูลสาขาใหม่","success");
        }else{
          this.$swal("",response.data.data,"warning");
        }
      })
      .catch((error)=>{
        this.$swal("","","warning");
        console.error(error);
      })
    },
    async submit_edit_branch(input){
      var url = this.api+'branch/save/'+this.secret;
      var data = new FormData();
      Object.keys(input).forEach((key) => {
        data.set(key, input[key]);
      });
      data.set("secret", this.secret);
      await this.Axios.post(url, data)
      .then((response)=>{
        // console.log(response.data);
        if(response.data.error==0){
          Object.keys(input).forEach((key) => {
            this.branch[key] = input[key];
          });
          this.branch = {};
          this.$swal("","Successful Save","success");
        }else{
          this.$swal("",response.data.data,"warning");
        }
      })
      .catch((error)=>{
        this.$swal("","","warning");
        console.error(error);
      })
    },
    remove_branch(val){
      this.$swal({
        title: "ลบสาขา?",
        text: "ชื่อสาขา: "+val.name,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          this.$swal({
            title: "คุณแน่ใจหรือไม่?",
            text: "ต้องการลบข้อมูลสาขา: "+val.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
              var url = this.api+'branch/delete/'+this.secret;
              var data = new FormData();
              data.set("id", val.id);
              data.set("u_id", val.u_id);
              data.set("secret", this.secret);
              await this.Axios.post(url, data)
              .then((response)=>{
                if(response.data.error==0){
                  const INDEX = this.branchs.map((e) => parseInt(e.id)).indexOf(parseInt(val.id));
                  this.branchs.splice(INDEX, 1);
                  this.$swal("", "ลบสาขา "+val.name, "success");
                }else{
                  this.$swal("",response.data.data,"warning");
                }
              })
              .catch((error)=>{
                this.$swal("","","warning");
                console.error(error);
              })
            }
          });
        }
      });
    }
  },
};
</script>
