import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Func from './Func.vue'
Vue.mixin(Func);

import GetData from './GetData.vue'
Vue.mixin(GetData);

import Branch from './Branch.vue'
Vue.mixin(Branch);

import Meta from 'vue-meta'
Vue.use(Meta)

import './../node_modules/bulma/css/bulma.css';
import './../node_modules/bootstrap-grid-only-css/dist/css/bootstrap-grid.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueSwal from 'vue-swal'
Vue.use(VueSwal)

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)

Vue.config.productionTip = false

new Vue({
  router,
  metaInfo(){
    return { title: "TNJ - Power Meter" }
  },
  render: h => h(App)
}).$mount('#app')
