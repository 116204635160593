<script>
export default {
  methods: {
    aa(val){
      alert(val);
      console.log(val);
    }
  },
};
</script>
